import {
  DASHBOARD_DATA_FETCH_URL,
  PREDICTION_AVAILABLE_CHECK_FETCH_URL,
  PROJECTS_LIST_FETCH_URL,
  PROJECT_INFO_BY_ID_FETCH_URL,
  PROJECT_MODEL_FETCH_URL,
  TRIGGER_EVAL_MODEL_FETCH_URL,
  TRIGGER_GET_PREDICTION_FETCH_URL,
  TRIGGER_PROJECT_DATA_UPLOAD_FETCH_URL,
  TRIGGER_TRAIN_MODEL_FETCH_URL,
  PIPELINES_FETCH_URL,
  PIPELINE_CREATE_URL,
  PIPELINE_BY_ID_URL,
  WORKSPACES_FETCH_URL,
  WORKSPACES_CREATE_URL,
  WORKSPACES_BY_ID_URL,
  KNOWLEDGE_BASE_CREATE_URL,
  KNOWLEDGE_BASE_LIST_URL,
  KNOWLEDGE_BASE_BY_ID_URL,
  TEMPLATE_PIPELINES_FETCH_URL,
  USER_PROFILE_FETCH_URL,
  USER_PROFILE_UPDATE_URL,
  RAGFLOW_API_TOKEN_FETCH_URL,
  PIPELINE_RUNS_CREATE_URL,
} from "../constants/endpoints";
import { getUrl } from "../helpers/navigation";
import { fetchWithTokenRefresh } from "../helpers/fetchWithTokenRefresh";

export const fetchDashboardData = async (workspaceId, projectId) => {
  const fetchURL = getUrl(DASHBOARD_DATA_FETCH_URL, { workspaceId, id: projectId });
  const response = await fetchWithTokenRefresh(fetchURL);
  return response.json();
};

export const fetchProjectInfoById = async (workspaceId, projectId) => {
  const fetchURL = getUrl(PROJECT_INFO_BY_ID_FETCH_URL, { workspaceId, id: projectId });
  const response = await fetchWithTokenRefresh(fetchURL);
  return response.json();
};

export const fetchTriggerEvalModel = async (workspaceId, projectId) => {
  const fetchURL = getUrl(TRIGGER_EVAL_MODEL_FETCH_URL, { workspaceId, id: projectId });
  const response = await fetchWithTokenRefresh(fetchURL, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({}),
  });
  return response.json();
};

export const fetchTriggerTrainModel = async (workspaceId, projectId) => {
  const fetchURL = getUrl(TRIGGER_TRAIN_MODEL_FETCH_URL, { workspaceId, id: projectId });
  const response = await fetchWithTokenRefresh(fetchURL, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({}),
  });
  return response.json();
};

export const fetchTriggerGetPrediction = async (workspaceId, projectId) => {
  const fetchURL = getUrl(TRIGGER_GET_PREDICTION_FETCH_URL, { workspaceId, id: projectId });
  const response = await fetchWithTokenRefresh(fetchURL, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({}),
  });
  return response.json();
};

export const fetchProjectDataUpload = async (workspaceId, projectId, fileList) => {
  const fetchURL = getUrl(TRIGGER_PROJECT_DATA_UPLOAD_FETCH_URL, { workspaceId, id: projectId });
  const formData = new FormData();
  fileList.forEach(file => formData.append("files", file));

  const response = await fetchWithTokenRefresh(fetchURL, {
    method: "POST",
    body: formData,
  });
  return response.json();
};

export const fetchProjectsList = async (workspaceId) => {
  const fetchURL = getUrl(PROJECTS_LIST_FETCH_URL, { workspaceId });
  const response = await fetchWithTokenRefresh(fetchURL);
  return response.json();
};

export const fetchPredictionAvailableCheck = async (workspaceId, projectId) => {
  const fetchURL = getUrl(PREDICTION_AVAILABLE_CHECK_FETCH_URL, { workspaceId, id: projectId });
  const response = await fetchWithTokenRefresh(fetchURL);
  return response.json();
};

export const fetchProjectModelData = async (workspaceId, projectId, type) => {
  const fetchURL = getUrl(PROJECT_MODEL_FETCH_URL, { workspaceId, id: projectId });
  const params = {
    type,
    sort_by: "start_time",
    ascending: true,
    page: 0,
    results_per_page: 10,
  };

  const response = await fetchWithTokenRefresh(fetchURL, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(params),
  });
  return response.json();
};

export const fetchPipelines = async (workspaceId) => {
  const fetchURL = getUrl(PIPELINES_FETCH_URL, { workspaceId });
  const response = await fetchWithTokenRefresh(fetchURL);
  return response.json();
};

export const fetchPipelineTemplates = async (workspaceId) => {
  const fetchURL = getUrl(TEMPLATE_PIPELINES_FETCH_URL, { workspaceId });
  const response = await fetchWithTokenRefresh(fetchURL);
  return response.json();
};

export const createPipeline = async (workspaceId, pipelineData) => {
  const fetchURL = getUrl(PIPELINE_CREATE_URL, { workspaceId });
  const response = await fetchWithTokenRefresh(fetchURL, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(pipelineData),
  });
  return response.json();
};

export const fetchPipelineById = async (workspaceId, pipelineId) => {
  const fetchURL = getUrl(PIPELINE_BY_ID_URL, { workspaceId, pipelineId });
  const response = await fetchWithTokenRefresh(fetchURL);
  return response.json();
};

export const updatePipeline = async (workspaceId, pipelineId, data) => {
  const fetchURL = getUrl(PIPELINE_BY_ID_URL, { workspaceId, pipelineId });
  const response = await fetchWithTokenRefresh(fetchURL, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
  return response.json();
};

export const deletePipeline = async (workspaceId, pipelineId) => {
  const fetchURL = getUrl(PIPELINE_BY_ID_URL, { workspaceId, pipelineId });
  const response = await fetchWithTokenRefresh(fetchURL, {
    method: "DELETE",
  });
  return response.json();
};

export const fetchKnowledgeBaseById = async (workspaceId, knowledgeBaseId) => {
  const fetchURL = getUrl(KNOWLEDGE_BASE_BY_ID_URL, { workspaceId, knowledgeBaseId });
  const response = await fetchWithTokenRefresh(fetchURL);
  return response.json();
};

export const fetchDeleteKnowledgeBaseById = async (workspaceId, knowledgeBaseId) => {
  const fetchURL = getUrl(KNOWLEDGE_BASE_BY_ID_URL, { workspaceId, knowledgeBaseId });
  const response = await fetchWithTokenRefresh(fetchURL, {
    method: "DELETE",
  });
  return response.json();
};

export const fetchWorkspaces = async () => {
  const fetchURL = getUrl(WORKSPACES_FETCH_URL);
  const response = await fetchWithTokenRefresh(fetchURL);
  return response.json();
};

export const createWorkspace = async (data) => {
  const fetchURL = getUrl(WORKSPACES_CREATE_URL);
  const response = await fetchWithTokenRefresh(fetchURL, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
  return response.json();
};

export const fetchWorkspaceById = async (workspaceId) => {
  const fetchURL = getUrl(WORKSPACES_BY_ID_URL, { workspaceId });
  const response = await fetchWithTokenRefresh(fetchURL);
  return response.json();
};

export const createKnowledgeBase = async (workspaceId, data) => {
  const fetchURL = getUrl(KNOWLEDGE_BASE_CREATE_URL, { workspaceId });
  const response = await fetchWithTokenRefresh(fetchURL, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
  return response.json();
};

export const fetchKnowledgeBaseList = async (workspaceId) => {
  const fetchURL = getUrl(KNOWLEDGE_BASE_LIST_URL, { workspaceId });
  const response = await fetchWithTokenRefresh(fetchURL);
  return response.json();
};

export const fetchUserProfile = async () => {
  const response = await fetchWithTokenRefresh(USER_PROFILE_FETCH_URL);
  return response.json();
};

export const updateUserProfile = async (data) => {
  const response = await fetchWithTokenRefresh(USER_PROFILE_UPDATE_URL, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
  return response.json();
};

export const fetchRagflowApiToken = async () => {
  const response = await fetchWithTokenRefresh(RAGFLOW_API_TOKEN_FETCH_URL);
  return response.json();
};

export const fetchUpdateWorkspace = async (workspaceId, data) => {
  const fetchURL = getUrl(WORKSPACES_BY_ID_URL, { workspaceId });
  const response = await fetchWithTokenRefresh(fetchURL, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
  return response.json();
};

export const fetchDeleteWorkspace = async (workspaceId) => {
  const fetchURL = getUrl(WORKSPACES_BY_ID_URL, { workspaceId });
  const response = await fetchWithTokenRefresh(fetchURL, {
    method: "DELETE",
  });
  return response.json();
};

export const fetchSavePipelineRun = async (workspaceId, pipelineId, data) => {
  const fetchURL = getUrl(PIPELINE_RUNS_CREATE_URL, { workspaceId, pipelineId });
  const response = await fetchWithTokenRefresh(fetchURL, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
  return response.json();
};
